import { Box, CircularProgress } from "@mui/joy";
import logo from "../assets/logo.png";
import useStore from "../Stores";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const InitializingScreen = () => {
	const store = useStore();
	const location = useLocation();

	useEffect(() => {
		const venueName = location.pathname.split("/")[2];
		store.setVenueName(venueName);
	}, [location.pathname]);
	return (
		<Box
			sx={{
				width: "100vw",
				height: "100vh",
			}}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
				}}>
				<img
					src={logo}
					alt="logo"
					style={{
						width: "110px",
						height: "110px",
						borderRadius: "10px",
					}}
				/>
				<CircularProgress
					sx={{
						position: "absolute",
						bottom: "-40px",
						left: "40%",
					}}
					size="sm"
				/>
			</Box>
		</Box>
	);
};

export default InitializingScreen;
