import {
	Box,
	Button,
	CircularProgress,
	FormLabel,
	Input,
	Option,
	Select,
	Typography,
} from "@mui/joy";
import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

const bookingSystems = [
	"GameTime",
	"SportyHQ",
	"ClubLocker",
	"Fusion",
	"GigaSports",
	"Squash on Fire",
	"Club Spark",
	"Skedda",
	"Jonas",
	"Tennis Bookings",
	"Court Reserve",
	"Daxko",
	"Active Network",
	"Club Wise",
	"Reserve My Court",
	"Amilia",
	"Kourts",
	"Mind and Body",
	"i-Reservation",
	"Others",
];

const IdentifyBookingSystem = () => {
	const [bookingSystem, setBookingSystem] = useState<string | null>(null);
	const [otherSystem, setOtherSystem] = useState<string>("");
	const [loading, setLoading] = useState(false);

	const location = useLocation();

	const path = location.pathname.split("/").pop();

	const onSubmit = async () => {
		try {
			if (!bookingSystem) return;
			setLoading(true);
			await axios.post(
				"https://backendapi.activscout.com/club-booking-system",
				{
					bookingSystem:
						bookingSystem !== "others" ? bookingSystem : otherSystem,
					club_name_unique: path,
				}
			);
			setLoading(false);
			alert("Booking system submitted successfully");
		} catch (error) {
			console.error("Error submitting booking system", error);
			setLoading(false);
			alert("Error submitting booking system");
		}
	};
	return (
		<Box
			sx={{
				textAlign: "initial",
				mt: 5,
			}}>
			<Typography level="body-md">Help us serve you better</Typography>
			<Typography level="h4">
				Which booking system does your club use?
			</Typography>
			<Select
				value={bookingSystem}
				onChange={(e, val) => setBookingSystem(val)}
				placeholder="Select">
				{bookingSystems.map((system) => (
					<Option key={system} value={system.toLowerCase().replace(" ", "")}>
						{system}
					</Option>
				))}
			</Select>
			{bookingSystem === "others" && (
				<Box mt={2}>
					<Input
						value={otherSystem}
						onChange={(e) => setOtherSystem(e.target.value)}
						placeholder="Enter the name of the booking system"
					/>
				</Box>
			)}
			<Button
				onClick={onSubmit}
				sx={{
					mt: 3,
					float: "right",
				}}>
				{loading ? <CircularProgress size={"sm"} /> : "Submit"}
			</Button>
		</Box>
	);
};

export default IdentifyBookingSystem;
