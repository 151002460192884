import { Box, Button, Input, Typography } from "@mui/joy";
import background from "../assets/yellow1.png";
import { useState } from "react";
import { validateEmail } from "../utils";

export default function ContactUs() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	// send to https://backendapi.activscout.com/contact-us
	const handleSubmit = async () => {
		const data = {
			name,
			email,
			subject,
			message,
		};

		if (!name || !email || !subject || !message)
			return alert("Please fill all fields");

		if (!validateEmail(email)) return alert("Please enter a valid email");

		await fetch("https://backendapi.activscout.com/contact-us", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		setName("");
		setEmail("");
		setSubject("");
		setMessage("");

		alert("Message sent successfully");
	};

	return (
		<Box
			sx={{
				width: "100%",
				height: {
					xs: "calc(100vh + 150px)",
					md: "calc(100vh )",
				},
				position: "relative",
				textAlign: "center",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}>
			<img
				src={background}
				alt="background"
				style={{
					position: "absolute",
					width: "100%",
					height: "100%",
					// objectFit: "cover",
					zIndex: -1,
					// clipPath: "polygon(0 0,100% 0,100% calc(100% - 150px),0 100%)",
				}}
			/>
			<Box
				sx={{
					width: {
						xs: "90%",
						md: "40%",
					},
					mt: 10,
				}}>
				<Typography
					level="h1"
					sx={{
						color: "white",
					}}>
					CONTACT US
				</Typography>
				<Typography
					sx={{
						color: "white",
					}}>
					If you are interested in squash as a participant or as an entity
					dedicated to providing programming or infrastructure for squash, we
					want to hear from you. Email us and let us know who you are and how we
					can help you on your journey.
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							md: "row",
						},
						justifyContent: "center",
					}}>
					<Input
						placeholder="YOUR NAME"
						sx={{ width: "50%", my: 2, mr: 2 }}
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						placeholder="EMAIL ADDRESS"
						sx={{ width: "50%", my: 2 }}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Box>
				<Input
					placeholder="SUBJECT"
					sx={{ width: "100%", my: 2 }}
					value={subject}
					onChange={(e) => setSubject(e.target.value)}
				/>
				<Input
					placeholder="MESSAGE"
					sx={{ width: "100%", height: 200, my: 2 }}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>
				<Button
					onClick={handleSubmit}
					sx={{
						display: "flex",
						alignSelf: "flex-start",
						background: "#a40e4c",
					}}>
					<Typography sx={{ color: "white" }}>SUBMIT</Typography>
				</Button>
			</Box>
		</Box>
	);
}
