import * as React from "react";
import Table from "@mui/joy/Table";
import useStore from "../Stores";
import {
	Avatar,
	Box,
	CircularProgress,
	Dropdown,
	IconButton,
	Menu,
	MenuButton,
	MenuItem,
} from "@mui/joy";
import { MoreVert } from "@mui/icons-material";
import TablePagination from "../components/TablePagination";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Image } from "../Stores/imagesSlice";
import StatusSelector from "../components/StatusSelector";
import ImageViewer from "../../Components/ImageViewer";

export default function ImagesScreen() {
	const Store = useStore();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(20);
	const [status, setStatus] = React.useState<"pending" | "active" | "inactive">(
		"pending"
	);
	const [selectedImage, setSelectedImage] = React.useState("");
	const fetching = React.useRef(false);

	React.useEffect(() => {
		if (
			page > 1 &&
			Store.images.length < page * perPage &&
			(Store.totalImages > Store.images.length || Store.totalImages === 0)
		) {
			if (!fetching.current) {
				fetching.current = true;
				fetchImages(false).then(() => (fetching.current = false));
			}
		}
	}, [page, perPage]);

	React.useEffect(() => {
		fetchImages(true);
		setPage(1);
	}, [status]);

	const fetchImages = async (reset: boolean) => {
		setLoading(true);
		await Store.getImages(reset, {
			skip: (page - 1) * perPage,
			limit: perPage,
			status,
		});
		setLoading(false);
	};

	const images = React.useMemo(
		() => Store.images.slice((page - 1) * perPage, page * perPage),
		[Store.images, page, perPage]
	);

	const approveImage = (imageId: string) => {
		Store.updateImage(imageId, {
			status: "active",
		});
	};

	const hideImage = (imageId: string) => {
		Store.updateImage(imageId, {
			status: "inactive",
		});
	};

	const removeImage = (imageId: string) => {
		Store.deleteImage(imageId);
	};

	return (
		<div>
			<StatusSelector
				value={status}
				onChange={(status: any) => setStatus(status)}
			/>
			{loading ? (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "200px",
					}}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<Table
						aria-label="basic table"
						variant="outlined"
						sx={{
							width: {
								xs: "auto",
								md: "100%",
							},
							mt: 2,
							background: "white",
						}}>
						<thead>
							<tr>
								<th style={{ width: "10%" }}>Image</th>
								<th>Asset ID</th>
								<th>Club Name Unique</th>
								<th>Status</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{images.map((image: Image, index: number) => (
								<tr key={index}>
									<td>
										<img
											onClick={() =>
												setSelectedImage(
													"https://storage.googleapis.com/activscout/" +
														image.asset
												)
											}
											src={
												"https://storage.googleapis.com/activscout/" +
												image.asset
											}
											alt="image1"
											style={{
												width: "70px",
												height: "70px",
												cursor: "pointer",
											}}
										/>
									</td>
									<td>{image.asset}</td>
									<td>{image.club_name_unique}</td>
									<td>{image.status}</td>
									<td>
										{/* <IconButton variant="outlined">
									<Edit
										sx={{
											width: "20px",
										}}
									/>
								</IconButton> */}
										<Dropdown>
											<MenuButton
												slots={{ root: IconButton }}
												slotProps={{
													root: { variant: "outlined", color: "neutral" },
												}}
												sx={{ ml: "10px" }}>
												<MoreVert />
											</MenuButton>
											<Menu>
												{status === "pending" ? (
													<MenuItem onClick={() => approveImage(image._id)}>
														Approve
													</MenuItem>
												) : (
													status === "active" && (
														<MenuItem onClick={() => hideImage(image._id)}>
															Hide
														</MenuItem>
													)
												)}
												<MenuItem onClick={() => removeImage(image._id)}>
													Remove
												</MenuItem>
											</Menu>
										</Dropdown>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<TablePagination
						perPage={perPage}
						total={Store.totalImages}
						page={page}
						onPageChange={setPage}
						onPerPageChange={setPerPage}
					/>
				</>
			)}
			<ImageViewer src={selectedImage} />
		</div>
	);
}
