import React, { useMemo } from "react";
import TimeList, { getTimesArr } from "./TimeList";
import { Box, Typography } from "@mui/joy";
import plus from "../../assets/plus.png";
import moment from "moment";

export default function CourtBookingComponent() {
	const blocksList = useMemo(() => getTimesArr("08:00", "22:00", 30), []); // get the time blocks
	const courts = [1, 2, 3, 4];
	return (
		<Box>
			<Box
				sx={{
					height: "30px",
					background: "#C9C9C9",
					width: "calc(30 * 5)",
					display: "flex",
					justifyContent: "space-between",
					border: "2px solid #C9C9C9",
				}}>
				<Box
					sx={{
						width: "59.5%",
						background: "white",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Typography>My Reservations</Typography>
				</Box>
				<Box
					sx={{
						width: "40%",
						background: "orange",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Typography fontWeight={"bold"}>
						{moment().format("MMMM DD")}
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					width: {
						xs: "100vw",
						sm: "auto",
					},
				}}>
				<TimeList startTime="08:00" endTime="22:00" length={30} />
				<Box
					sx={{
						display: "flex",
						background: "#E1E1E1",
						overflowX: {
							xs: "scroll",
							sm: "hidden",
						},
					}}>
					{courts.map((item) => (
						<Box>
							<Box
								sx={{
									width: "100px",
									height: "30px",
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									textAlign: "center",
									borderTop: "1px solid #C9C9C9",
									borderRight:
										courts[courts.length - 1] === item
											? "1px solid #C9C9C9"
											: "none",
									background: "#E1E1E1",
								}}>
								<Typography fontSize={14} sx={{ color: " #929095" }}>
									Court {item}
								</Typography>
							</Box>
							{blocksList.map(() => (
								<Box
									key={item}
									sx={{
										width: "100px",
										height: "30px",
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										textAlign: "center",
										border: "1px solid #C9C9C9",
										background: "#a8becb",
									}}>
									<img
										src={plus}
										alt="plus"
										style={{
											width: 20,
											height: 20,
										}}
									/>
								</Box>
							))}
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}
