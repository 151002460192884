import { StateCreator, StoreApi } from "zustand";
import { IStore } from ".";

export interface Image {
	_id: string;
	asset: string;
	club_name_unique?: string;
	club_id?: string;
	status: string;
}

type GetImagesOptions = {
	category?: string;
	skip?: number;
	limit?: number;
	status?: string;
};

export interface IImagesSlice {
	images: Image[];
	totalImages: number;
	getImage: (_id: string) => Promise<Image>;
	getImages: (reset: boolean, options: GetImagesOptions) => Promise<void>;
	addImage: (image: Image) => Promise<any>;
	updateImage: (id: string, image: Partial<Image>) => Promise<any>;
	deleteImage: (_id: string) => Promise<any>;
}

export const createImagesSlice: StateCreator<IStore, [], [], IImagesSlice> = (
	set: StoreApi<IStore>["setState"],
	get: StoreApi<IStore>["getState"]
) => ({
	images: [],
	totalImages: 0,
	getImage: async (_id: string) => {
		const image = await get().app.service("club-images").get(_id);
		return image;
	},
	getImages: async (reset, { skip, limit, status }) => {
		const venueName = window.location.pathname.split("/")[2];
		const images = await get()
			.app.service("club-images")
			.find({
				query: {
					$skip: skip,
					$limit: limit,
					status,
					$sort: { createdAt: -1 },
					club_name_unique: venueName,
				},
			});

		set({
			images: reset ? [...images.data] : [...get().images, ...images.data],
			totalImages: images.total,
		});
	},
	addImage: async (image: Image) => {
		const response = await get().app.service("club-images").create(image);
		set({ images: [...get().images, response] });
	},
	updateImage: async (id: string, image: Partial<Image>) => {
		await get().app.service("club-images").patch(id, image);
		set({
			images: get().images.filter((i) => i._id !== id),
		});
	},
	deleteImage: async (_id: string) => {
		await get().app.service("club-images").remove(_id);
		set({
			images: get().images.filter((i) => i._id !== _id),
		});
	},
});
