import { Button, Textarea } from "@mui/joy";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { useEffect, useRef, useState } from "react";
import useStore from "../Stores";

const TextsScreen = () => {
	const store = useStore();
	const [texts, setTexts] = useState({
		_id: "",
		facilityInfo: "",
	});
	const [loading, setLoading] = useState(false);

	const service = useRef(store.app.service("club-texts")).current;

	useEffect(() => {
		fetchFacilityInfo();
	}, []);

	const fetchFacilityInfo = async () => {
		try {
			const venueName = window.location.pathname.split("/")[2];
			const data = await service.find({
				query: { club_name_unique: venueName },
			});

			if (data.total) {
				setTexts(data.data[0]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const saveFacilityInfo = async () => {
		const venueName = window.location.pathname.split("/")[2];
		setLoading(true);
		if (texts._id) {
			await service.patch(texts._id, {
				facilityInfo: texts.facilityInfo,
			});
		} else {
			await service.create({
				club_name_unique: venueName,
				facilityInfo: texts.facilityInfo,
			});
		}
		setLoading(false);
		alert("Info saved successfully");
	};

	return (
		<Box>
			<FormLabel
				sx={{
					fontSize: "1.5rem",
					fontWeight: "bold",
					marginBottom: "1rem",
				}}>
				Facility Info
			</FormLabel>
			<Textarea
				value={texts.facilityInfo}
				onChange={(e) => {
					setTexts((prev) => ({
						...prev,
						facilityInfo: e.target.value,
					}));
				}}
				sx={{
					height: "200px",
				}}
			/>
			<Button
				loading={loading}
				onClick={saveFacilityInfo}
				variant="solid"
				sx={{ mt: 2 }}>
				Save
			</Button>
		</Box>
	);
};

export default TextsScreen;
