import { Box, Button, Input, Typography } from "@mui/joy";
import React from "react";
import Header from "../Components/Header";
import CourtBookingComponent from "../Components/CourtBookingComponent";
import Footer from "../Components/Footer";

export default function CourtBooking() {
	return (
		<>
			<Box
				sx={{
					background: "#9bafbc",
					minHeight: 300,
					pb: 2,
				}}>
				<Header aboutRef={null} teamRef={null} contactUsRef={null} />
				<Box
					sx={{
						px: {
							xs: 2,
							sm: 10,
						},
						pt: 3,
					}}>
					<Typography
						level="h1"
						fontSize={{
							xs: 30,
							sm: 45,
						}}>
						MEADOW PARK RECREATION CENTRE
					</Typography>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "30%",
							},
							marginLeft: "auto",
							mt: {
								xs: 2,
								sm: 0,
							},
						}}>
						<Typography
							level="h2"
							sx={{
								color: "white",
								fontSize: {
									xs: 20,
									sm: 30,
								},
							}}>
							SQUASH BOOKING LOGIN
						</Typography>
						<Box
							sx={{
								display: "flex",
								mt: 2,
							}}>
							<Input
								placeholder="Username"
								sx={{
									height: 50,
									mr: 1,
								}}
							/>
							<Input
								placeholder="Password"
								sx={{
									height: 50,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: {
						xs: "column",
						sm: "row",
					},
					justifyContent: "space-around",
					marginTop: 2,
					mb: 2,
				}}>
				<CourtBookingComponent />
				<Box
					sx={{
						mt: {
							xs: 2,
							sm: 0,
						},
					}}>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: 400,
							},
							height: 400,
							background: "#E1E1E1",
							borderRadius: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Typography>
							<h2>Advertisement</h2>
						</Typography>
					</Box>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: 400,
							},
							height: 400,
							background: "#E1E1E1",
							borderRadius: 10,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: 2,
						}}>
						<Typography>
							<h2>Event Promotion</h2>
						</Typography>
					</Box>
					<Button
						sx={{ mt: 1, ml: "auto", background: " #e6a83a", width: 150 }}>
						<Typography>Register</Typography>
					</Button>
				</Box>
			</Box>
			<Footer aboutRef={null} teamRef={null} contactUsRef={null} />
		</>
	);
}
