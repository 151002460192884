import { Box, Typography } from "@mui/joy";
import logo from "../assets/logo.png";

export default function UniteAndGrow() {
	return (
		<Box
			sx={{
				display: {
					sm: "block",
					md: "flex",
				},
				justifyContent: "space-between",
				alignItems: "center",
				px: {
					sm: 0,
					md: 20,
				},
			}}>
			<Box
				sx={{
					py: 10,
					px: {
						xs: 5,
						md: 0,
					},
					width: {
						xs: "80%",
						md: "50%",
					},
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
					// alignItems: "center",
					// textAlign: "center",
				}}>
				<Typography level="h1">UNITE AND GROW</Typography>
				<Typography level="body-md">
					At ClubHub, we’re not just a platform; we’re a community.{"\n"}
				</Typography>
				<Typography level="body-md">
					Together, we can:{"\n"} 
					* Connect federations and associations with new players.{"\n"}
				</Typography>
				<Typography level="body-md">
					{" "}
					* Help clubs as they onboard new members.{"\n"}{" "}
				</Typography>
				<Typography level="body-md">
					* Support event programmers in reaching a broader audience.{"\n"}
				</Typography>
				<Typography level="body-md">
					{" "}
					* Remove the barriers that keep players off the court.{"\n"}
				</Typography>{" "}
				<Typography level="body-md">
					* Standardize and enhance the squash industry with the top software
					partners.
				</Typography>
				<Typography level="body-md">
					{" "}
					* Amplify the voices of coaches, athletes, and media channels,
					spreading the passion for squash to every corner of the globe. Join us
					on ClubHub and be part of the squash revolution
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<img
					src={logo}
					alt="logo"
					width={90}
					height={90}
					// style={{
					// 	position: "absolute",
					// 	bottom: -140,
					// 	left: "50%",
					// 	transform: "translateX(-50%)",
					// }}
				/>
				<Typography level="h1">CLUBHUB</Typography>
			</Box>
		</Box>
	);
}
