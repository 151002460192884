import { Box, Button, Typography, styled } from "@mui/joy";
import phone2 from "../assets/phone2.png";
import { Link } from "react-router-dom";

const Img = styled("img")(({ theme }) => ({
	width: 400,
	[theme.breakpoints.down("sm")]: {
		width: 200,
	},
}));

export default function StakeHolders() {
	return (
		<div style={{ position: "relative" }}>
			<Box
				sx={{
					px: {
						sm: 0,
						md: 10,
					},
					py: 10,
					width: {
						xs: "100%",
						md: "40%",
						xl: "30%",
					},
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
				}}>
				<Typography level="h1">SUPPORTING OUR STAKEHOLDERS</Typography>
				<Typography level="body-md">
					From associations and federations to clubs, venues, coaches, players,
					brands and software providers, ClubHub supports today’s top club
					software providers integrated into a single social network to bring
					together the entire squash community like never before.
				</Typography>
				<Link
					style={{ textDecoration: "none" }}
					to={"https://activscout.page.link/download"}>
					<Button sx={{ width: 231, mt: 3, mb: 3, background: "#a40e4c" }}>
						<Typography sx={{ color: "white" }}>DOWNLOAD THE APP</Typography>
					</Button>
				</Link>
				<Typography level="h1">
					TO CREATE THE BEST SQUASH APP FOR PLAYERS
				</Typography>
			</Box>
			<Box
				sx={{
					position: {
						xs: "relative",
						md: "absolute",
					},
					bottom: {
						xs: 40,
						md: 0,
					},
					right: {
						xs: 0,
						md: 60,
					},
					display: {
						xs: "flex",
						md: "block",
					},
					justifyContent: {
						xs: "center",
						md: "flex-end",
					},
					zIndex: 1,
				}}>
				<Img src={phone2} alt="phone2" />
			</Box>
		</div>
	);
}
