import Hero from "../Components/Hero";
import Mission from "../Components/Mission";
import StakeHolders from "../Components/StakeHolders";
import Partners from "../Components/Partners";
import ContactUs from "../Components/ContactUs";
import UniteAndGrow from "../Components/UniteAndGrow";
import Footer from "../Components/Footer";
import { useRef } from "react";

function Home() {
	const aboutRef = useRef(null);
	const teamRef = useRef(null);
	const contactUsRef = useRef(null);
	return (
		<>
			<Hero aboutRef={aboutRef} teamRef={teamRef} contactUsRef={contactUsRef} />
			<div ref={aboutRef}>
				<Mission />
			</div>
			<div ref={teamRef}>
				<StakeHolders />
				{/* <Partners /> */}
			</div>
			<div ref={contactUsRef}>
				<ContactUs />
			</div>
			<UniteAndGrow />
			<Footer
				aboutRef={aboutRef}
				teamRef={teamRef}
				contactUsRef={contactUsRef}
			/>
		</>
	);
}

export default Home;
