// src/App.js

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
	MapContainer,
	TileLayer,
	Marker,
	Tooltip,
	useMap,
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import L from "leaflet";
// import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet-easybutton/src/easy-button.js";
import { Link } from "react-router-dom";
import { LoaderOverlay } from "../Components/LoaderOverlay";
import MyLocationButton from "../Components/MyLocationButton";

// Fix Leaflet's default icon issue with Webpack
// delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
	iconRetinaUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon-2x.png",
	iconUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-icon.png",
	shadowUrl:
		"https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/marker-shadow.png",
});

interface Venue {
	id: number;
	name: string;
	latitude: string;
	longitude: string;
	no_of_courts: number;
	name_unique: string;
}

const VenuesMap = () => {
	const [venues, setVenues] = useState<Venue[]>([]);
	const [loading, setLoading] = useState(true);
	const circle = useRef<any>(null);

	useEffect(() => {
		setLoading(true);
		axios
			.get("https://squash-search.ctoninja.tech/venues/all")
			.then((response) => {
				setLoading(false);
				setVenues(response.data);
			})
			.catch((error) => {
				setLoading(false);
				console.error("There was an error fetching the venues!", error);
			});
	}, []);

	const escapeHtml = (text: string) => {
		const map = {
			"&": "&amp;",
			"<": "&lt;",
			">": "&gt;",
			'"': "&quot;",
			"'": "&#039;",
		};
		return text.replace(/[&<>"']/g, (m) => (map as any)[m]);
	};

	const Markers = () => (
		<MarkerClusterGroup>
			{venues.map((venue) => (
				<Marker key={venue.id} position={[+venue.latitude, +venue.longitude]}>
					<Tooltip permanent direction="right" interactive>
						<a
							href={"/vu/" + venue.name_unique}
							target="_blank"
							rel="noreferrer">
							<b>{escapeHtml(venue.name)}</b>
							<br />
							Squash courts: {venue.no_of_courts}
						</a>
					</Tooltip>
				</Marker>
			))}
		</MarkerClusterGroup>
	);

	// const GeocoderControl = () => {
	// 	const map = useMap();

	// 	useEffect(() => {
	// 		L.Control.geocoder({
	// 			defaultMarkGeocode: false,
	// 		})
	// 			.on("markgeocode", (e) => {
	// 				const bbox = e.geocode.bbox;
	// 				const poly = L.polygon([
	// 					bbox.getSouthEast(),
	// 					bbox.getNorthEast(),
	// 					bbox.getNorthWest(),
	// 					bbox.getSouthWest(),
	// 				]).addTo(map);
	// 				map.fitBounds(poly.getBounds());
	// 			})
	// 			.addTo(map);
	// 	}, [map]);

	// 	return null;
	// };

	const UserLocationControl = () => {
		const map = useMap();

		useEffect(() => {
			const greenIcon = L.icon({
				iconUrl:
					"https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
				shadowUrl:
					"https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
				iconSize: [25, 41],
				iconAnchor: [12, 41],
				popupAnchor: [1, -34],
				shadowSize: [41, 41],
			});

			const urlParams = new URLSearchParams(window.location.search);
			const returnLat = urlParams.get("returnLat");
			const returnLng = urlParams.get("returnLng");
			const returnZoom = urlParams.get("returnZoom") || "128";

			if (returnLat && returnLng) {
				map.setView(
					[parseFloat(returnLat), parseFloat(returnLng)]
					// parseInt(returnZoom)
				);
			} else {
				map.locate({ setView: false, maxZoom: 16 });
			}

			map.on("locationfound", (e) => {
				L.marker(e.latlng, { icon: greenIcon })
					.addTo(map)
					.bindTooltip("You", { permanent: true, offset: [0, -20] });

				// if circle is already there, don't add another one
				if (circle.current) {
					return;
				}
				circle.current = L.circle(e.latlng, { radius: e.accuracy }).addTo(map);
				// zoom the map to the circle x-5
				map.fitBounds(circle.current.getBounds().pad(0.5));
			});

			// map.on("locationerror", (e: any) => {
			// 	if (e.code !== e.PERMISSION_DENIED) {
			// 		alert(e.message);
			// 	}
			// });
		}, [map]);

		return null;
	};

	return (
		<div className="App">
			<MapContainer
				center={[0, 0]}
				zoom={2}
				style={{ height: "100vh" }}
				maxBounds={[
					[-90, -180],
					[90, 180],
				]}
				maxBoundsViscosity={1.0}>
				<MyLocationButton />
				<TileLayer
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					attribution="© OpenStreetMap contributors"
					maxZoom={19}
				/>
				<Markers />
				{/*{/* <GeocoderControl /> */}
				<UserLocationControl />
				{/* <EasyButtonControl /> */}
			</MapContainer>
			{loading && <LoaderOverlay />}
		</div>
	);
};

export default VenuesMap;
