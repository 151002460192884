import {
	Home,
	KeyboardArrowRight,
	People,
	SettingsSuggest,
} from "@mui/icons-material";
import {
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemContent,
	ListItemDecorator,
	Typography,
} from "@mui/joy";

interface Props {
	routes?: any;
}

export default function SidebarComponent({ routes }: Props) {
	return (
		<div
			style={{
				padding: "20px",
				height: "100vh",
			}}>
			<Box
				sx={{
					// background: "red",
					height: "100%",
					borderRadius: 10,
					boxShadow: "0px 0px 10px 0px #0000000D",
				}}>
				<Box
					sx={{
						height: 100,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderBottomColor: "black",
						borderBottomWidth: 1,
					}}>
					<Typography level="h3">ClubHub</Typography>
				</Box>

				<List sx={{ margin: "10px" }}>
					{routes.map((route: any, index: any) => (
						<ListItem key={index}>
							<ListItemButton
								sx={{
									borderRadius: 10,
								}}>
								<ListItemDecorator>
									<route.icon />
								</ListItemDecorator>
								<ListItemContent>{route.label}</ListItemContent>
								<KeyboardArrowRight />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Box>
		</div>
	);
}
