import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createAuthSlice, IAuthSlice } from "./authSlice";
import { createMainSlice, IMainStore } from "./MainStore";
import { IImagesSlice, createImagesSlice } from "./imagesSlice";

export type IStore = IMainStore & IImagesSlice & IAuthSlice;

const useStore = create<IStore>()(
	devtools((...a) => ({
		...createMainSlice(...a),
		...createImagesSlice(...a),
		...createAuthSlice(...a),
	}))
);

export default useStore;
