import { useEffect, useState } from "react";
import useStore from "./Stores";
import { redirect } from "react-router-dom";

const useAuth = () => {
	const Store = useStore();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		Store.initAuth()
			.then(() => {
				console.log("Auth initialized");
				setIsLoaded(true);
				redirect("/vu/:clubname/dashboard");
			})
			.catch((error: any) => {
				if (error.message.includes("No accessToken found in storage")) {
					setIsLoaded(true);
				}
				console.log("Error initializing auth", error);
			});
	}, []);

	return { isLoaded, isLoggedIn: Store.authUser };
};

export default useAuth;
