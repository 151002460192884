import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import logo from "../assets/logo.png";
import useStore from "../Stores";
import { redirect } from "react-router-dom";

interface FormElements extends HTMLFormControlsCollection {
	email: HTMLInputElement;
	password: HTMLInputElement;
	persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
	readonly elements: FormElements;
}

export default function LoginScreen() {
	const Store = useStore();
	const [username, setUsername] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [error, setError] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const handleLogin = async () => {
		const venueName = window.location.pathname.split("/")[2];
		try {
			setLoading(true);
			setError("");
			await Store.login(username, password);
			setLoading(false);
			redirect("/vu" + venueName + "/admin/dashboard");
		} catch (error: any) {
			setLoading(false);
			setError(error.message);
		}
	};

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange>
			<CssBaseline />
			<GlobalStyles
				styles={{
					":root": {
						"--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
						"--Cover-width": "50vw", // must be `vw` only
						"--Form-maxWidth": "800px",
						"--Transition-duration": "0.4s", // set to `none` to disable transition
					},
				}}
			/>
			<Box
				sx={(theme) => ({
					width: "100vw",
					transition: "width var(--Transition-duration)",
					transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
					position: "relative",
					zIndex: 1,
					display: "flex",
					justifyContent: "center",
					backdropFilter: "blur(12px)",
					backgroundColor: "rgba(255 255 255 / 0.2)",
					[theme.getColorSchemeSelector("dark")]: {
						backgroundColor: "rgba(19 19 24 / 0.4)",
					},
				})}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						// minHeight: "100dvh",
						width:
							"clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
						maxWidth: "100%",
						px: 2,
					}}>
					<Box
						component="header"
						sx={{
							pt: 3,
							display: "flex",
							alignItems: "left",
							justifyContent: "center",
						}}>
						<Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
							<img
								src={logo}
								alt="React logo"
								width="70"
								height="70"
								style={{ borderRadius: 10 }}
							/>
						</Box>
					</Box>
					<Box
						component="main"
						sx={{
							mt: 5,
							pb: 5,
							display: "flex",
							flexDirection: "column",
							gap: 2,
							width: 400,
							maxWidth: "100%",
							mx: "auto",
							borderRadius: "sm",
							"& form": {
								display: "flex",
								flexDirection: "column",
								gap: 2,
							},
							[`& .${formLabelClasses.asterisk}`]: {
								visibility: "hidden",
							},
						}}>
						<Stack gap={4} sx={{ mb: 2 }}>
							<Stack gap={1}>
								<Typography component="h1" level="h3">
									Sign in
								</Typography>
							</Stack>
							{error && (
								<Typography
									sx={{
										color: "red",
										fontSize: "sm",
										fontWeight: "bold",
									}}>
									Error: {error}
								</Typography>
							)}
						</Stack>
						<Divider
							sx={(theme) => ({
								[theme.getColorSchemeSelector("light")]: {
									color: { xs: "#FFF", md: "text.tertiary" },
									"--Divider-lineColor": {
										xs: "#FFF",
										md: "var(--joy-palette-divider)",
									},
								},
							})}></Divider>
						<Stack gap={4} sx={{ mt: 2 }}>
							<form
								onSubmit={(event: React.FormEvent<SignInFormElement>) => {
									event.preventDefault();
									const formElements = event.currentTarget.elements;
									const data = {
										email: formElements.email.value,
										password: formElements.password.value,
										persistent: formElements.persistent.checked,
									};
									alert(JSON.stringify(data, null, 2));
								}}>
								<FormControl required>
									<FormLabel>Username</FormLabel>
									<Input
										type="email"
										name="email"
										value={username}
										onChange={(e) => setUsername(e.target.value)}
									/>
								</FormControl>
								<FormControl required>
									<FormLabel>Password</FormLabel>
									<Input
										type="password"
										name="Password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</FormControl>
								<Stack gap={4} sx={{ mt: 2 }}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}>
										<Checkbox size="sm" label="Remember me" name="persistent" />
									</Box>
									<Button
										onClick={handleLogin}
										// type="submit"
										fullWidth
										loading={loading}>
										{"Sign in"}
									</Button>
								</Stack>
							</form>
						</Stack>
					</Box>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
