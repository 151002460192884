import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import React, { useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import squash_players_app from "../assets/squash-players.svg";
import innosoft_fusion from "../assets/innosoft-fusion.png";
import world_squash_federation from "../assets/World_Squash_Federation_logo.svg.png";
import clublocker from "../assets/clublocker-logo-horizontal-v2.svg";
import ImageCarousel from "./ImageCarousel";

export default function Partners() {
	const partners = [
		{
			title: "INNOSOFT FUSION",
			image: innosoft_fusion,
		},
		// {
		// 	text: "WORLD SQUASH FEDERATION",
		// 	image: world_squash_federation,
		// },
		{
			title: "CLUBLOCKER",
			image: clublocker,
		},
		{
			title: "SQUASH PLAYERS APP",
			image: squash_players_app,
		},
	];

	return (
		<Box
			sx={{
				py: 10,
				px: {
					sm: 5,
					md: 10,
				},
				textAlign: "center",
			}}>
			<Typography level="h1">MEET OUR PARTNERS</Typography>
			<Box sx={{ py: 10 }}>
				<ImageCarousel
					data={partners}
					imageProps={{
						width: "220px",
						style: {
							objectFit: "contain",
						},
					}}
				/>
			</Box>
		</Box>
	);
}
