import { Box } from "@mui/joy";
import InfoWidget from "../components/InfoWidget";
import { useEffect, useState } from "react";
import useStore from "../Stores";

export default function DashboardScreen() {
	const store = useStore();
	const [total, setTotal] = useState({
		pending: 0,
		active: 0,
		inactive: 0,
	});

	useEffect(() => {
		(async () => {
			const venueName = window.location.pathname.split("/")[2];
			const service = store.app.service("club-images");
			const [pending, active, inactive] = await Promise.all(
				["pending", "active", "inactive"].map((status) =>
					service.find({
						query: { status, club_name_unique: venueName, $limit: 1 },
					})
				)
			);
			setTotal({
				pending: pending.total,
				active: active.total,
				inactive: inactive.total,
			});
		})();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				flexDirection: {
					xs: "column",
					md: "row",
				},
				alignItems: "center",
				gap: 2,
			}}>
			<InfoWidget
				title="Approved"
				value={total.active as any}
				icon="👥"
				color="primary"
			/>
			<InfoWidget
				title="Pending"
				value={total.pending as any}
				icon="👥"
				color="primary"
			/>
			<InfoWidget
				title="Hidden"
				value={total.inactive as any}
				icon="👥"
				color="primary"
			/>
		</Box>
	);
}
