import * as React from "react";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import Input from "@mui/joy/Input";
import List from "@mui/joy/List";
import ListItemButton from "@mui/joy/ListItemButton";
import Typography from "@mui/joy/Typography";
import ModalClose from "@mui/joy/ModalClose";
import Search from "@mui/icons-material/Search";
import useStore from "../Stores";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/joy";
import { Logout } from "@mui/icons-material";

interface Props {
	routes: any;
}

export default function DrawerComponent({ routes }: Props) {
	const Store = useStore();
	return (
		<Drawer open={Store.drawerOpen} onClose={() => Store.toggleDrawer(false)}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: 0.5,
					ml: "auto",
					mt: 1,
					mr: 2,
				}}>
				<Typography
					component="label"
					htmlFor="close-icon"
					fontSize="sm"
					fontWeight="lg"
					sx={{ cursor: "pointer" }}>
					Close
				</Typography>
				<ModalClose id="close-icon" sx={{ position: "initial" }} />
			</Box>
			<List
				size="lg"
				component="nav"
				sx={{
					flex: "none",
					fontSize: "xl",
					"& > div": { justifyContent: "center" },
				}}>
				{routes.map((route: any, index: any) => (
					<Link
						to={route.to}
						key={index}
						style={{ textDecoration: "none" }}
						onClick={() => Store.toggleDrawer(false)}>
						<ListItemButton>{route.label}</ListItemButton>
					</Link>
				))}
				<Link
					to={""}
					style={{ textDecoration: "none" }}
					onClick={() => {
						Store.logout();
						Store.toggleDrawer(false);
					}}>
					<ListItemButton>Logout</ListItemButton>
				</Link>
			</List>
		</Drawer>
	);
}
