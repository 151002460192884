import {
	Box,
	Button,
	Divider,
	IconButton,
	Input,
	Typography,
	styled,
} from "@mui/joy";
import logo from "../assets/logo.png";
import Header from "../Components/Header";
import {
	ChevronLeft,
	ChevronRight,
	Mail,
	Phone,
	PinDrop,
	PlayArrowOutlined,
	Upload,
} from "@mui/icons-material";
import Partners from "../Components/Partners";
import Footer from "../Components/Footer";
import herobackground from "../assets/clubbanner.gif";
import phone from "../assets/phone1.png";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { useEffect, useMemo, useRef, useState } from "react";
import NewsLetter from "../Components/NewsLetter";
import IdentifyBookingSystem from "../Components/IdentifyBookingSystem";
import ImageCarousel from "../Components/ImageCarousel";
import ImageUploadModal from "../Components/ImageUploadModal";
import axios from "axios";
import useStore from "../admin/Stores";
import { validateEmail } from "../utils";

const Img = styled("img")();

const FacilityInfo = ({ title, value }: any) => (
	<Box
		sx={{
			display: {
				xs: "block",
				sm: "flex",
			},
			mb: 1,
			alignItems: "center",
			flex: "25%",
		}}>
		<Box
			sx={{
				background: " #c4c4c4",
				width: {
					xs: "100%",
					sm: 200,
				},
				height: 40,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}>
			<Typography>{title}</Typography>
		</Box>

		<Typography ml={3} fontWeight={900}>
			{value}
		</Typography>
	</Box>
);

export default function Clubsites() {
	const store = useStore();
	const [venue, setVenue] = useState<any>(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [images, setImages] = useState<any>([]);
	const [imageUploadModalOpen, setImageUploadModalOpen] = useState(false);
	const [texts, setTexts] = useState({
		facilityInfo: "",
	});

	const sliderRef = useRef<Slider>(null);
	const homeRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);
	const contactUsRef = useRef<HTMLDivElement>(null);

	const venueName = useParams<{ venueName: string }>().venueName;

	useEffect(() => {
		const fetchVenue = async () => {
			const response = await fetch(
				`https://squash-search.ctoninja.tech/venues/get-by-name/${venueName}`
			);
			const data = await response.json();

			setVenue(data);
		};

		fetchVenue();
	}, [venueName]);

	useEffect(() => {
		const fetchImages = async () => {
			const response = await fetch(
				`https://backendapi.activscout.com/club-images/?club_name_unique=${venue?.name_unique}&status=active`
			);
			const data = await response.json();
			if (data.total === 0) return;
			setImages(data.data);
		};

		if (venue) fetchImages();
	}, [venue]);

	useEffect(() => {
		fetchFacilityInfo();
	}, []);

	const fetchFacilityInfo = async () => {
		try {
			const venueName = window.location.pathname.split("/")[2];
			const data = await store.app.service("club-texts").find({
				query: { club_name_unique: venueName },
			});

			if (data.total) {
				setTexts(data.data[0]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const goToSlide = (index: number) => {
		setCurrentSlide(index);
		sliderRef.current?.slickGoTo(index);
	};

	const handleSubmit = async () => {
		const data = {
			name,
			email,
			subject,
			message,
		};

		if (!name || !email || !subject || !message)
			return alert("Please fill all fields");

		if (!validateEmail(email)) return alert("Please enter a valid email");
		await fetch("https://backendapi.activscout.com/contact-us", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		});

		setName("");
		setEmail("");
		setSubject("");
		setMessage("");

		alert("Message sent successfully");
	};

	const isMobile = useMemo(() => window.innerWidth < 768, []);
	const isTablet = useMemo(() => window.innerWidth < 900, []);
	const accessFields = [
		{
			title: "MEMBERS ONLY?",
			value: venue?.member_only === "Yes" ? "YES" : "NO",
		},
		{
			title: "GUESTS WELCOME",
			value: venue?.guest_welcome === "Yes" ? "YES" : "NO",
		},
		{
			title: "PAY & PLAY",
			value: venue?.pay_play === "Yes" ? "YES" : "NO",
		},
	];

	const courtsFields = [
		{
			title: "Total Courts",
			value: `${venue?.no_of_courts} courts`,
		},
		{
			title: "Single Courts",
			value: venue?.no_of_singles_courts + " courts",
		},
		{
			title: "Glass Courts",
			value: venue?.no_of_glass_courts + " courts",
		},
		{
			title: "Outdoor Courts",
			value: venue?.no_of_outdoor_courts + " courts",
		},
		{
			title: "Double Courts",
			value: venue?.no_of_doubles_courts + " courts",
		},
		{
			title: "Hardball Doubles Courts",
			value: venue?.no_of_hardball_doubles_courts + " courts",
		},
	];
	const clubFeaturesFields = [
		{
			title: "Sports Shop",
			value: venue?.sports_shop === "Yes" ? "YES" : "NO",
		},
		{
			title: "Hot Showers",
			value: venue?.hot_shower === "Yes" ? "YES" : "NO",
		},
		{
			title: "Parking",
			value: venue?.parking === "Yes" ? "YES" : "NO",
		},
		{
			title: "GYM",
			value: venue?.gym === "Yes" ? "YES" : "NO",
		},
		{
			title: "Multi-Sport Venue",
			value: venue?.multi_sport_venue === "Yes" ? "YES" : "NO",
		},
		{
			title: "Cafe",
			value: venue?.cafe === "Yes" ? "YES" : "NO",
		},
		{
			title: "Serves Alcohol",
			value: venue?.serves_alcohol === "Yes" ? "YES" : "NO",
		},
		{
			title: "Water Fountain",
			value: venue?.water_fountain === "Yes" ? "YES" : "NO",
		},
		{
			title: "Vending Machine",
			value:
				(venue?.drink_vending_machine || venue?.snack_vending_machine) === "Yes"
					? "YES"
					: "NO",
		},
		{
			title: "Online Booking",
			value: venue?.online_booking === "Yes" ? "YES" : "NO",
		},
		{
			title: "24hr Access",
			value: venue?.twentyfour_hour_access === "Yes" ? "YES" : "NO",
		},
		{
			title: "Permanently Staffed",
			value: venue?.permanently_staffed === "Yes" ? "YES" : "NO",
		},
		{
			title: "Re-stringing Service",
			value: venue?.re_stringing_service === "Yes" ? "YES" : "NO",
		},
	];

	return (
		<div>
			<Box
				ref={homeRef}
				sx={{
					width: "100%",
					height: "700px",
					position: "relative",
				}}>
				<img
					src={herobackground}
					alt="hero"
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
						zIndex: -1,
					}}
				/>
				<Header
					aboutRef={aboutRef}
					teamRef={null}
					contactUsRef={contactUsRef}
				/>

				<Box
					sx={{
						width: {
							xs: "90%",
							sm: "50%",
						},
						mx: {
							xs: "auto",
							md: 10,
						},
						mt: {
							xs: 5,
							sm: 10,
						},
					}}>
					<Typography
						sx={{
							color: "white",
							fontWeight: "600",
							fontSize: {
								xs: 38,
								sm: 45,
							},
							mb: 2,
						}}>
						{venue?.name}
					</Typography>
					<Typography
						fontSize={18}
						sx={{
							color: "white",
						}}>
						Located at:{"\n"} {venue?.physical_address}
					</Typography>

					<Button
						onClick={() => window.open(venue?.g_map_url, "_blank")}
						sx={{
							mt: 3,
							width: 200,
							height: 35,
							backgroundColor: " #a40e4c",
						}}>
						<Typography
							level="title-sm"
							sx={{ color: "white", fontWeight: "bold" }}>
							GOOGLE MAPS
						</Typography>
					</Button>

					{venue?.website && (
						<div>
							<Button
								onClick={() => window.open(venue.website, "_blank")}
								sx={{
									mt: 3,
									width: 200,
									height: 35,
									backgroundColor: "white",
								}}>
								<Typography
									level="title-sm"
									sx={{ color: "#312f9e", fontWeight: "bold" }}>
									OFFICIAL WEBSITE
								</Typography>
							</Button>
						</div>
					)}
				</Box>
				{/* <img
					src={logo}
					alt="logo"
					width={125}
					height={125}
					style={{
						position: "absolute",
						bottom: -140,
						left: "50%",
						transform: "translateX(-50%)",
						zIndex: 1,
					}}
				/> */}
			</Box>

			<Box
				ref={aboutRef}
				sx={{
					mt: {
						xs: 5,
						sm: 0,
					},
					px: {
						xs: 3,
						sm: 10,
					},
					pb: 10,
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
				}}>
				<Typography level="h1">FACILITY INFO</Typography>

				<Typography level="body-lg" mt={3}>
					{texts.facilityInfo
						? texts.facilityInfo
						: `For those of you who are looking to take up the sport of squash or are
					on the move and looking for a new club to play at, we want to make it
					easy for you. To get on court. Check out the information below and see
					if there is a fit.`}
				</Typography>

				{/* <Typography level="h1" mt={2}>
					{venue?.name}
				</Typography> */}

				<Box
					sx={{
						width: {
							xs: "90%",
							sm: "50%",
							xl: "40%",
						},
						alignSelf: "center",
						marginLeft: "auto",
						marginRight: "auto",
						mt: 5,
						textAlign: "center",
					}}>
					<Typography level="h3" mb={3}>
						ACCESS
					</Typography>

					<Box
						sx={{
							display: {
								xs: "block",
								sm: "flex",
							},
							flexWrap: "wrap",
						}}>
						{accessFields.map(({ title, value }) => (
							<FacilityInfo title={title} value={value} />
						))}
					</Box>
					<Typography level="h3" mt={5} mb={3}>
						COURTS
					</Typography>
					<Box
						sx={{
							display: {
								xs: "block",
								sm: "flex",
							},
							flexWrap: "wrap",
						}}>
						{courtsFields.map(({ title, value }) => (
							<FacilityInfo title={title} value={value} />
						))}
					</Box>
					<Typography level="h3" mt={5} mb={3}>
						CLUB FEATURES
					</Typography>
					<Box
						sx={{
							display: {
								xs: "block",
								sm: "flex",
							},
							flexWrap: "wrap",
						}}>
						{clubFeaturesFields.map(({ title, value }) => (
							<FacilityInfo title={title} value={value} />
						))}
					</Box>
					<IdentifyBookingSystem />
				</Box>

				<Box
					sx={{
						position: "relative",
						pt: {
							xs: 5,
							md: 0,
						},
					}}>
					<Divider
						sx={{
							my: 7,
						}}
					/>
					<Img
						src={logo}
						alt="logo"
						width={60}
						height={60}
						sx={{
							position: "absolute",
							bottom: {
								xs: -30,
								sm: 25,
							},
							left: "50%",
							transform: "translateX(-50%)",
							zIndex: 1,
						}}
					/>
				</Box>
				<Box py={7.5}>
					<Box
						sx={{
							width: {
								xs: "100%",
								sm: "56%",
							},
							marginLeft: "auto",
							display: "flex",
							justifyContent: "space-between",
						}}>
						<Typography level="h1">GALLERY</Typography>
						<IconButton
							variant="outlined"
							onClick={() => setImageUploadModalOpen(true)}>
							<Upload />
							Upload
						</IconButton>
					</Box>
					<Box
						sx={{
							py: 10,
						}}>
						{images.length > 0 ? (
							<ImageCarousel
								data={images}
								carouselProps={{
									slidesToShow: isMobile
										? 1
										: isTablet
										? 3
										: images.length < 5
										? images.length
										: 5,
								}}
							/>
						) : (
							<Typography textAlign={"center"}>No images found</Typography>
						)}
					</Box>
					<ImageUploadModal
						open={imageUploadModalOpen}
						onClose={() => setImageUploadModalOpen(false)}
					/>
				</Box>
				{/* <Box
					sx={{
						textAlign: "center",
						py: 7,
					}}>
					<Typography level="h1">NEWS AND EVENTS</Typography>
					<Typography level="body-sm" mt={2}>
						Nulla lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
						cillum dolor. Voluptate exercitation incididunt aliquip deserunt.
					</Typography>

					<Box
						sx={{
							// display: "flex",
							// justifyContent: "center",
							// flexWrap: "wrap",
							width: {
								xs: "100%",
								sm: 700,
							},
							mt: 5,
							marginLeft: "auto",
							marginRight: "auto",
						}}>
						<Slider
							ref={sliderRef}
							onSwipe={(swipeDirection) => {
								if (swipeDirection === "left") {
									goToSlide(currentSlide + 1);
								} else {
									goToSlide(currentSlide - 1);
								}
							}}
							{...{
								arrows: false,
								dots: false,
								infinite: true,
								speed: 500,
								slidesToShow: isMobile ? 1 : 3,
								slidesToScroll: 0,
							}}>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
								<Box
									sx={{
										mx: 1,
										textAlign: {
											xs: "center",
											sm: "initail",
										},
										width: 220,
										display: {
											xs: item === 1 ? "block" : "none",
											sm: "block",
										},
									}}>
									<Box
										sx={{
											width: 220,
											background: "#c4c4c4",
											height: 180,
											marginLeft: "auto",
											marginRight: "auto",
										}}></Box>

									<Button
										sx={{
											width: 150,
											height: 35,
											backgroundColor: "#F1A40A",
											mt: 2,
										}}>
										<Typography
											level="title-sm"
											sx={{ color: "white", fontWeight: "bold" }}>
											Launch Event
										</Typography>
									</Button>
									<Typography level="h4" mt={1}>
										Lorem ipsum dolor sit amet elit eiusmod.
									</Typography>
								</Box>
							))}
						</Slider>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							mt: 5,
						}}>
						<IconButton
							onClick={() => {
								goToSlide(currentSlide - 1);
							}}
							variant="outlined">
							<ChevronLeft />
						</IconButton>
						{[1, 2, 3, 4].map((item) => (
							<Button
								onClick={() => goToSlide(isMobile ? item - 1 : item * 3 - 3)}
								variant="outlined"
								sx={{
									mx: 1,
									background: item === currentSlide + 1 ? "#F1A40A" : "white",
									color: item === currentSlide + 1 ? "white" : "black",
								}}>
								{item}
							</Button>
						))}
						<IconButton
							onClick={() => {
								goToSlide(currentSlide + 1);
							}}
							variant="outlined">
							<ChevronRight />
						</IconButton>
					</Box>
				</Box> */}
			</Box>
			<Box>
				<Box
					sx={{
						position: "relative",
						marginBottom: { xs: "calc(100vh + 300px )", sm: "calc(88.5vh )" },
					}}>
					<Box
						sx={{
							width: "100%",
							height: {
								xs: 590,
								sm: 590,
							},
							objectFit: "cover",
							clipPath: {
								xs: "none",
								sm: "polygon(0 0,100% 0,100% 100%,0 calc(100% - 20%))",
							},
							background: "#83abbc",
							textAlign: "left",
						}}>
						<Box
							sx={{
								width: {
									xs: "90%",
									md: 500,
								},
								pt: 10,
								pl: {
									xs: 3,
									md: 10,
								},
								boxSizing: "initial",
							}}>
							<Typography level="h1" sx={{ color: "white" }}>
								ORGANIZE A GAME TODAY
							</Typography>
							<Typography level="body-md" sx={{ color: "white" }} mt={1}>
								ClubHub provides every club around the globe a free website and
								app to help clubs grow their membership. Both the website and
								app are free. The website will help prospective players find
								your courts while the app allows existing club members to easily
								message one another and book a court.
							</Typography>
							<Typography level="body-md" sx={{ color: "white" }} mt={1}>
								Check out our video to see why ClubHub is the easiest way to
								organize your squash games with a regular partner or with
								someone new.
							</Typography>

							<Box
								sx={{
									display: "block",
									// alignItems: "center",
									mt: 3,
								}}>
								<Button
									onClick={() =>
										window.open(
											"https://apps.apple.com/us/app/clubhub-social-network/id1642694740",
											"_blank"
										)
									}
									sx={{
										width: 270,
										height: 35,
										backgroundColor: " #a40e4c",
									}}>
									<Typography
										level="title-sm"
										sx={{ color: "white", fontWeight: "bold" }}>
										DOWNLOAD FROM APP STORE
									</Typography>
								</Button>
								<Button
									onClick={() =>
										window.open(
											"https://play.google.com/store/apps/details?id=com.app.activscout&hl=en_IN",
											"_blank"
										)
									}
									sx={{
										width: 270,
										height: 35,
										backgroundColor: " #a40e4c",
										mt: 2,
									}}>
									<Typography
										level="title-sm"
										sx={{ color: "white", fontWeight: "bold" }}>
										DOWNLOAD FROM PLAY STORE
									</Typography>
								</Button>
								{/* <IconButton
									sx={{
										backgroundColor: " #a40e4c",
										width: 60,
										height: 35,
										ml: 3,
									}}>
									<PlayArrowOutlined sx={{ fontSize: 35, color: "white" }} />
								</IconButton> */}
							</Box>
						</Box>
					</Box>

					<Img
						src={phone}
						alt="phone"
						sx={{
							position: "absolute",
							bottom: -10,
							right: 80,
							zIndex: 2,
							width: 340,
							display: {
								xs: "none",
								sm: "block",
							},
						}}
					/>
					<img
						src={logo}
						alt="logo"
						style={{
							position: "absolute",
							width: "65px",
							height: "65px",
							top: -30,
							left: "50%",
							transform: "translateX(-50%)",
						}}
					/>
					<Box
						sx={{
							position: "absolute",
							bottom: {
								xs: -30,
								sm: 30,
							},
							left: "50%",
							transform: "translateX(-50%)",
						}}>
						<img
							src={logo}
							alt="logo"
							style={{ width: "65px", height: "65px" }}
						/>
					</Box>
					<Box
						ref={contactUsRef}
						sx={{
							width: "100%",
							height: {
								xs: "auto",
								md: "930px",
							},
							objectFit: "cover",
							clipPath: {
								xs: "none",
								// sm: "polygon(0 0,100% 0,100% 100%,0 calc(100% - 20%))",
							},
							// background: "#E79301",
							textAlign: "center",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: {
								xs: "column",
								sm: "row",
							},
							position: "absolute",
							top: {
								xs: "620px",
								sm: "400px",
							},
							zIndex: 1,
						}}>
						<Box
							sx={{
								width: {
									xs: "90%",
									md: "40%",
								},
								pt: {
									xs: 0,
									md: 10,
								},
							}}>
							<Typography
								level="h1"
								sx={
									{
										// color: "white",
									}
								}>
								CONTACT US
							</Typography>
							{/* <Typography
								sx={
									{
										// color: "white",
									}
								}>
								Nulla lorem mollit cupidatat irure. Laborum magna nulla duis
								ullamco cillum dolor. Voluptate exercitation incididunt aliquip
								deserunt.
							</Typography> */}
							<Box
								sx={{
									display: "flex",
									flexDirection: {
										xs: "column",
										md: "row",
									},
									justifyContent: "center",
								}}>
								<Input
									placeholder="YOUR NAME"
									value={name}
									onChange={(e) => setName(e.target.value)}
									sx={{ width: "50%", my: 2, mr: 2 }}
								/>
								<Input
									placeholder="EMAIL ADDRESS"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									sx={{ width: "50%", my: 2 }}
								/>
							</Box>
							<Input
								placeholder="SUBJECT"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
								sx={{ width: "100%", my: 2 }}
							/>
							<Input
								placeholder="MESSAGE"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								sx={{ width: "100%", height: 200, my: 2 }}
							/>
							<Button
								onClick={handleSubmit}
								sx={{
									display: "flex",
									alignSelf: "flex-start",
									background: "#a40e4c",
								}}>
								<Typography sx={{ color: "white" }}>SUBMIT</Typography>
							</Button>
						</Box>
						<Box
							sx={{
								height: "640px",
								width: {
									xs: "100%",
									sm: 300,
								},
								background: "#00272b",
								ml: {
									xs: 0,
									sm: 5,
								},
								mt: {
									xs: 2,
									sm: 0,
								},
								display: "flex",
								textAlign: "left",
								flexDirection: "column",
								justifyContent: "center",
								zIndex: -1,
								clipPath: {
									xs: "none",
									sm: "polygon(0 0,100% 2%,100% 100%,0 100%)",
								},
							}}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mt: 5,
									px: 3,
								}}>
								<Phone />
								<Box ml={1}>
									<Typography sx={{ color: "white" }}>Phone</Typography>
									<Typography sx={{ color: "white" }}>
										{venue?.telephone}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mt: 5,
									px: 3,
								}}>
								<Mail />
								<Box ml={1}>
									<Typography sx={{ color: "white" }}>Email</Typography>
									<Typography sx={{ color: "white" }}>
										{venue?.email || "-"}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mt: 5,
									px: 3,
								}}>
								<PinDrop />
								<Box ml={1}>
									<Typography sx={{ color: "white" }}>Location</Typography>
									<Typography sx={{ color: "white" }}>
										{venue?.physical_address}
									</Typography>
								</Box>
							</Box>
						</Box>
						<Divider />
					</Box>
				</Box>
			</Box>

			<Partners />
			<NewsLetter venue={{ name: venue?.name, id: venue?.id }} />
			<Footer aboutRef={aboutRef} teamRef={null} contactUsRef={contactUsRef} />
		</div>
	);
}
