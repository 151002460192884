import { Box, Button, Drawer, IconButton, Typography } from "@mui/joy";
import logo from "../assets/logo.png";
import { Menu } from "@mui/icons-material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface HeaderProps {
	aboutRef: any;
	teamRef: any;
	contactUsRef: any;
}

export default function Header({
	aboutRef,
	teamRef,
	contactUsRef,
}: HeaderProps) {
	const [open, setOpen] = useState(false);
	const menuItems = ["HOME", "ABOUT", "CONTACT US"];
	const navigate = useNavigate();
	const scrollTo = (ref: any) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<Box
			sx={{
				px: {
					xs: 3,
					md: 10,
				},
				display: "flex",
				justifyContent: "space-between",
				py: 3,
			}}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
				}}>
				<img src={logo} alt="logo" width={45} height={45} />
				<Typography
					level="h4"
					sx={{ ml: 1, fontWeight: "700", color: "white" }}>
					CLUBHUB
				</Typography>
			</Box>
			<Box
				sx={{
					display: {
						xs: "none",
						md: "flex",
					},
					alignItems: "center",
				}}>
				{menuItems.map((item, index) => (
					<Button
						variant="plain"
						onClick={() => {
							if (item === "HOME") {
								// navigate("/");
								window.location.reload();
							} else if (item === "ABOUT") {
								scrollTo(aboutRef);
							} else if (item === "TEAM") {
								scrollTo(teamRef);
							} else if (item === "CONTACT US") {
								scrollTo(contactUsRef);
							}
						}}
						sx={{
							":hover": {
								backgroundColor: "rgba(255, 255, 255, 0.1)",
							},
						}}
						key={index}>
						<Typography
							key={index}
							level="title-lg"
							sx={{
								mx: 2,
								color: "white",
								cursor: "pointer",
							}}>
							{item}
						</Typography>
					</Button>
				))}
			</Box>
			<Box
				sx={{
					display: {
						xs: "none",
						md: "flex",
					},
					alignItems: "center",
				}}>
				{/* <Button variant="solid">Order</Button> */}
			</Box>
			<IconButton
				onClick={() => setOpen(true)}
				variant="solid"
				sx={{
					display: {
						md: "none",
					},
				}}>
				<Menu />
			</IconButton>

			<Drawer open={open} onClose={() => setOpen(false)} anchor="right">
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						pt: 5,
					}}>
					{menuItems.map((item, index) => (
						<Button
							variant="plain"
							onClick={() => {
								if (item === "HOME") {
									window.scrollTo(0, 0);
								} else if (item === "ABOUT") {
									scrollTo(aboutRef);
								} else if (item === "TEAM") {
									scrollTo(teamRef);
								} else if (item === "CONTACT US") {
									scrollTo(contactUsRef);
								}
							}}
							sx={{
								":hover": {
									backgroundColor: "rgba(255, 255, 255, 0.1)",
								},
							}}
							key={index}>
							<Typography
								key={index}
								level="title-lg"
								sx={{
									mx: 2,
									cursor: "pointer",
								}}>
								{item}
							</Typography>
						</Button>
					))}
					{/* <Button variant="solid">Order</Button> */}
				</Box>
			</Drawer>
		</Box>
	);
}
