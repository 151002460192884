import { Box, Button, Typography, styled } from "@mui/joy";
import Header from "./Header";
import logo from "../assets/logo.png";
import blueMap from "../assets/bluemap.gif";
import phone1 from "../assets/phone1.png";
import { Link } from "react-router-dom";

const Img = styled("img")(({ theme }) => ({
	width: 400,
	[theme.breakpoints.down("sm")]: {
		width: 200,
	},
}));

export default function Hero(props: any) {
	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					position: "relative",
					width: "100%",
					height: 800,
				}}>
				<Header {...props} />
				<img
					src={blueMap}
					style={{
						position: "absolute",
						top: 0,
						zIndex: -1,
						width: "100%",
						height: 800,
						// objectFit: "cover",
						// clipPath: "polygon(0 0,100% 0,100% calc(100% - 150px),0 100%)",
					}}
					alt="blue map"
				/>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: {
							xs: "90%",
							md: "50%",
						},
						textAlign: "center",
					}}>
					<Typography
						sx={{
							color: "white",
							fontWeight: "600",
							fontSize: {
								xs: 38,
								sm: 45,
							},
							mb: 2,
						}}>
						FIND A SQUASH COURT NEAR YOU
					</Typography>
					<Typography
						fontSize={18}
						sx={{
							color: "white",
						}}>
						Search anywhere around the globe. If there is a club, we'll provide
						the information about the courts and their website
					</Typography>
					<Link to={"/vu"}>
						<Button
							sx={{
								mt: 3,
								width: 170,
								height: 50,
								backgroundColor: "white",
							}}>
							<Typography
								level="title-lg"
								sx={{ color: "#312f9e", fontWeight: "bold" }}>
								FIND COURTS
							</Typography>
						</Button>
					</Link>
				</Box>
				<img
					src={logo}
					alt="logo"
					width={90}
					height={90}
					style={{
						position: "absolute",
						bottom: 60,
						left: "50%",
						transform: "translateX(-50%)",
					}}
				/>
			</div>
			<Box
				sx={{
					px: {
						sm: 0,
						md: 10,
					},
					py: 10,
					width: {
						xs: "100%",
						md: "40%",
						xl: "30%",
					},
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
				}}>
				<Typography level="h1">CONNECT WITH SOMEONE TO PLAY</Typography>
				<Typography level="body-lg">
					ClubHub is the easiest way to organize a game and book a court with
					someone of equal ability anywhere in the world regardless of whether
					it is a regular weekly partner or someone new.
				</Typography>
				<Box
					sx={{
						display: "block",
						// alignItems: "center",
						mt: 3,
					}}>
					<Button
						onClick={() =>
							window.open(
								"https://apps.apple.com/us/app/clubhub-social-network/id1642694740",
								"_blank"
							)
						}
						sx={{
							width: 270,
							height: 35,
							backgroundColor: " #a40e4c",
						}}>
						<Typography
							level="title-sm"
							sx={{ color: "white", fontWeight: "bold" }}>
							DOWNLOAD FROM APP STORE
						</Typography>
					</Button>
					<Button
						onClick={() =>
							window.open(
								"https://play.google.com/store/apps/details?id=com.app.activscout&hl=en_IN",
								"_blank"
							)
						}
						sx={{
							width: 270,
							height: 35,
							backgroundColor: " #a40e4c",
							mt: 2,
						}}>
						<Typography
							level="title-sm"
							sx={{ color: "white", fontWeight: "bold" }}>
							DOWNLOAD FROM PLAY STORE
						</Typography>
					</Button>
				</Box>
			</Box>
			<Box
				sx={{
					position: {
						xs: "relative",
						md: "absolute",
					},
					bottom: {
						xs: 40,
						md: -80,
					},
					right: {
						xs: 0,
						md: 60,
					},
					display: {
						xs: "flex",
						md: "block",
					},
					justifyContent: {
						xs: "center",
						md: "flex-end",
					},
					zIndex: 1,
				}}>
				<Img src={phone1} alt="phone1" />
			</Box>
		</div>
	);
}
