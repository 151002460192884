import { Box, ListItem } from "@mui/joy";
import Grid from "@mui/joy/Grid";
import { Outlet, useLocation } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import DrawerComponent from "./DrawerComponent";
import { useMemo } from "react";

interface AuthLayoutProps {
	sidebar: React.ReactNode;
}

export default function AuthLayout(props: AuthLayoutProps) {
	const location = useLocation();

	const routes = useMemo(() => {
		const venueName = location.pathname.split("/")[2];

		return [
			{
				label: "Home",
				to: `/vu/${venueName}/admin/dashboard`,
			},
			{
				label: "Images",
				to: `/vu/${venueName}/admin/images`,
			},
			{
				label: "Texts",
				to: `/vu/${venueName}/admin/texts`,
			},
		];
	}, [location]);

	return (
		<Grid
			container
			sx={{
				height: "100%",
				width: "100%",
			}}>
			{/* <Grid
				xs={12}
				md={2}
				sx={{
					// borderRight: "1px solid #e0e0e0",
					display: {
						xs: "none",
						md: "block",
					},
					background: "white",
					boxShadow: "0px 0px 10px 0px #0000000D",
				}}>
				{props.sidebar}
			</Grid> */}
			<Grid
				xs={12}
				md={12}
				sx={{
					background: "#FCFDFD",
					overflow: "hidden",
				}}>
				<div
					style={{
						position: "fixed",
						width: "100%",
						zIndex: 1000,
					}}>
					<HeaderComponent routes={routes} />
				</div>
				<DrawerComponent routes={routes} />
				<Box
					sx={{
						padding: {
							xs: "10px",
							md: "10px 140px",
						},
						marginTop: "80px",
						overflow: "auto",
					}}>
					<Outlet />
				</Box>
			</Grid>
		</Grid>
	);
}
