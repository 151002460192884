import { Box, Typography } from "@mui/joy";
import logo from "../assets/logo.png";

export default function Mission() {
	return (
		<div style={{ position: "relative" }}>
			<div
				style={{
					width: "100%",
					height: "calc(100vh - 150px)",
					objectFit: "cover",
					clipPath: "polygon(0 0,100% 0,100% calc(100% - 150px),0 100%)",
					background: "#83abbc",
					textAlign: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				<Box
					sx={{
						width: {
							xs: "90%",
							md: "40%",
						},
					}}>
					<Typography level="h1" sx={{ color: "white" }}>
						OUR MISSION
					</Typography>
					<Typography level="body-md" sx={{ color: "white" }}>
						ClubHub is more than just a website; it’s a movement dedicated to
						increasing participation in squash. Everything we do is dedicated to
						our mission and every partner we take on maintains a similar guiding
						principle.
					</Typography>
				</Box>
			</div>
			<img
				src={logo}
				alt="logo"
				style={{
					position: "absolute",
					width: "90px",
					height: "90px",
					top: -40,
					left: "50%",
					transform: "translateX(-50%)",
				}}
			/>
			<img
				src={logo}
				alt="logo"
				style={{
					position: "absolute",
					width: "90px",
					height: "90px",
					bottom: 30,
					left: "50%",
					transform: "translateX(-50%)",
				}}
			/>
		</div>
	);
}
