import { StateCreator, StoreApi } from "zustand";
import { FeathersApplication } from "@feathersjs/feathers";
import app from "../feathers";
import { IStore } from ".";
import { AuthenticationClient } from "@feathersjs/authentication-client";

export interface IMainStore {
	app: FeathersApplication<any> & {
		authenticate: AuthenticationClient["authenticate"];
		reAuthenticate: AuthenticationClient["reAuthenticate"];
		logout: AuthenticationClient["logout"];
	};
	validateEmail: (email: string) => boolean;
	drawerOpen: boolean;
	toggleDrawer: (state: boolean) => void;
	venueName: string;
	setVenueName: (venueName: string) => void;
}

export const createMainSlice: StateCreator<IStore, [], [], IMainStore> = (
	set: StoreApi<IStore>["setState"],
	get: StoreApi<IStore>["getState"]
) => ({
	app: app,
	drawerOpen: false,
	venueName: "",

	setVenueName: (venueName: string) => set({ venueName }),
	toggleDrawer: (state: boolean) => {
		set({ drawerOpen: state });
	},

	validateEmail: (email: string) => {
		const re = /\S+@\S+\.\S+/;
		return re.test(email);
	},
});
