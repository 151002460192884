import { Box, Button, Input } from "@mui/joy";
import React from "react";
import { validateEmail } from "../utils";

interface NewsLetterProps {
	venue: {
		name: string;
		id: string | number;
	};
}

export default function NewsLetter(props: NewsLetterProps) {
	const [email, setEmail] = React.useState("");

	const handleSubscribe = async () => {
		try {
			if (!email) return alert("Please enter your email");
			if (!validateEmail(email)) return alert("Please enter a valid email");

			const response = await fetch(
				"https://backendapi.activscout.com/newsletter",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email,
						venue: props.venue,
					}),
				}
			);
			const data = await response.json();
			if (!data.message) {
				alert("Subscribed successfully");
				setEmail("");
			} else {
				alert(data.message);
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Box>
			<Box
				sx={{
					width: "100%",
					mb: 5,
					position: "relative",
					textAlign: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}}>
				<Box
					sx={{
						width: {
							xs: "100%",
							md: "50%",
						},
						backgroundColor: "white",
						borderRadius: 10,
						padding: 3,
						boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
					}}>
					<Box
						sx={{
							fontSize: 20,
							fontWeight: 700,
							color: "black",
							mb: 2,
						}}>
						Subscribe
					</Box>
					<Box
						sx={{
							fontSize: 14,
							color: "black",
							mb: 2,
						}}>
						Be the first of 200 clubs to receive a lifetime of free blogging on
						your website for free.
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: {
								xs: "column",
								md: "row",
							},
							gap: 2,
							px: {
								xs: 2,
								md: 0,
							},
						}}>
						<Input
							type="email"
							placeholder="Enter your email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							sx={{
								// padding: 10,
								width: {
									xs: "100%",
									md: "60%",
								},
								height: "50px",
								borderRadius: 5,
								border: "1px solid #ccc",
								marginBottom: {
									xs: 2,
									md: 0,
								},
							}}
						/>
						<Button
							onClick={handleSubscribe}
							sx={{
								padding: "10px 20px",
								borderRadius: 5,
								backgroundColor: "#a40e4c",
								color: "white",
								border: "none",
								cursor: "pointer",
								width: {
									xs: "100%",
									md: "auto",
								},
							}}>
							Subscribe
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
