import { ChangeEvent, FC, useEffect, useState } from "react";
import axios from "axios";
import {
	Box,
	Button,
	FormLabel,
	IconButton,
	Modal,
	ModalClose,
	Typography,
} from "@mui/joy";
import { nanoid } from "nanoid";
import { useLocation } from "react-router-dom";
import { ReactPhotoEditor } from "react-photo-editor";
import "react-photo-editor/dist/style.css";
import { Edit } from "@mui/icons-material";

interface ImageUploadModalProps {
	open: boolean;
	onClose: () => void;
}

const ImageUploadModal: FC<ImageUploadModalProps> = ({ open, onClose }) => {
	const [images, setImages] = useState<File[] | null>(null);
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [previewIndex, setPreviewIndex] = useState<number | null>(0);
	const [uploading, setUploading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [showEditor, setShowEditor] = useState(false);

	const location = useLocation();
	const club_name_unique = location.pathname.split("/")[2];

	useEffect(() => {
		setImages(null);
		setPreviewUrl(null);
		setPreviewIndex(0);
	}, [open]);

	const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (!files) return;

		setImages(Array.from(files));

		const file = files[0];
		const reader = new FileReader();

		reader.onload = () => {
			setPreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	const handleUpload = async () => {
		if (!images?.length) {
			setError("Please select an image");
			return;
		}

		const formData = new FormData();
		const assets: string[] = [];
		images.forEach((image) => {
			const name = nanoid();
			formData.append("images", image, name);
			assets.push(name);
		});

		formData.append("assets", JSON.stringify(assets));
		formData.append("club_name_unique", club_name_unique);

		try {
			setUploading(true);
			await axios.post(
				"https://backendapi.activscout.com/upload-club-images",
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			setUploading(false);
			alert("Image uploaded successfully");
			onClose();
			setImages(null);
			setPreviewUrl(null);
		} catch (error) {
			setError("Failed to upload image");
		}
	};

	const onSaveImage = (image: File) => {
		const newImages = images?.map((img, index) =>
			index === previewIndex ? image : img
		);
		setImages(newImages as File[]);
		setPreviewUrl(URL.createObjectURL(image));
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					backgroundColor: "white",
					padding: 3,
					borderRadius: 10,
					textAlign: "center",
					boxShadow: 24,
					width: {
						xs: "85%",
						md: "500px",
					},
					height: "auto",
					p: 5,
				}}>
				<ModalClose variant="outlined" />
				<FormLabel>
					<Box
						sx={{
							backgroundColor: "#f5f5f5",
							width: "100%",
							height: 300,
							borderRadius: 10,
							p: 2,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
						}}>
						{previewUrl && (
							<IconButton
								variant="outlined"
								sx={{
									position: "absolute",
									top: 10,
									right: 10,
								}}
								onClick={() => setShowEditor(true)}>
								<Edit />
							</IconButton>
						)}
						<input
							type="file"
							onChange={handleImageChange}
							accept="image/*"
							multiple
							style={{ display: "none" }}
						/>
						{previewUrl ? (
							<img src={previewUrl} alt="preview" style={{ width: "100%" }} />
						) : (
							<Typography>Click to select images</Typography>
						)}
					</Box>
				</FormLabel>
				<Box
					sx={{
						display: "flex",
						mt: 2,
						overflowX: "auto",
					}}>
					{images?.map((image, index) => (
						<Box
							onClick={() => {
								setPreviewUrl(URL.createObjectURL(image));
								setPreviewIndex(index);
							}}
							key={index}
							mx={1}>
							<img
								src={URL.createObjectURL(image)}
								alt="preview"
								style={{ width: 100 }}
							/>
						</Box>
					))}
				</Box>
				{error && <p style={{ color: "red" }}>{error}</p>}
				<Button loading={uploading} sx={{ mt: 5 }} onClick={handleUpload}>
					Upload
				</Button>
				<Modal open={showEditor} onClose={() => setShowEditor(false)}>
					<ReactPhotoEditor
						open={showEditor}
						onClose={() => setShowEditor(false)}
						file={images ? images[previewIndex as number] : undefined}
						onSaveImage={onSaveImage}
					/>
				</Modal>
			</Box>
		</Modal>
	);
};

export default ImageUploadModal;
