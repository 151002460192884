import { Box, Modal, ModalClose } from "@mui/joy";
import { useEffect, useMemo, useState } from "react";

const ImageViewer = ({ src }: { src: string }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (src) setOpen(true);
	}, [src]);

	const isMobile = useMemo(() => window.innerWidth < 768, []);

	return (
		<Modal open={open} onClose={() => setOpen(false)}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}>
				<ModalClose />
				<img
					src={src}
					alt="image"
					style={{
						width: isMobile ? "100%" : "50%",
					}}
				/>
			</Box>
		</Modal>
	);
};

export default ImageViewer;
