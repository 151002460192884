import { feathers } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
//@ts-expect-error - no types available
import io from "socket.io-client";
import authentication from "@feathersjs/authentication-client";

export const SERVER_URL =
	process.env.NODE_ENV === "development"
		? "http://localhost:8080"
		: "https://backendapi.activscout.com";

const socket = io(SERVER_URL, {
	transports: ["websocket"],
});

socket.on("connect", () => {
	console.log("connected to server");
});

const app = feathers();

// Setup the transport (Rest, Socket, etc.) here
app.configure(socketio(socket));

// Available options are listed in the "Options" section
app.configure(
	authentication({
		storage: window.localStorage,
	})
);

export default app;
