import * as React from "react";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";

export default function StatusSelector({ value, onChange }: any) {
	const handleChange = (
		event: React.SyntheticEvent | null,
		newValue: string | null
	) => {
		onChange(newValue);
	};
	return (
		<Select
			value={value}
			sx={{
				width: "120px",
			}}
			onChange={handleChange}>
			<Option value="active">Active</Option>
			<Option value="pending">Pending</Option>
			<Option value="inactive">Hidden</Option>
		</Select>
	);
}
