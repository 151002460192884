import { Box, Card, CardContent, Typography } from "@mui/joy";
import { ReactNode } from "react";

interface InfoWidgetProps {
	title: string;
	value: string;
	icon: ReactNode;
	color: string;
}

const InfoWidget = ({ title, value, icon, color }: InfoWidgetProps) => {
	return (
		<Card
			sx={{
				width: "180px",
				height: "180px",
				backgroundColor: color,
				borderRadius: "10px",
			}}>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					height: "100%",
				}}>
				<Typography level="h1">{value}</Typography>
				<Typography level="h3" mt={2}>
					{title}
				</Typography>
			</Box>
		</Card>
	);
};

export default InfoWidget;
