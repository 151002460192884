import { East, West } from "@mui/icons-material";
import { Box, IconButton, Option, Select } from "@mui/joy";

interface TablePaginationProps {
	perPage: number;
	total: number;
	page: number;
	onPageChange: (page: number) => void;
	onPerPageChange: (perPage: number) => void;
}

export default function TablePagination(props: TablePaginationProps) {
	const { perPage, total, page, onPageChange, onPerPageChange } = props;

	const onPrevPress = () => {
		if (page > 1) {
			onPageChange(page - 1);
		}
	};

	const onNextPress = () => {
		if (page < Math.ceil(total / perPage)) {
			onPageChange(page + 1);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "10px 30px",
			}}>
			<Box sx={{ mr: "10px" }}>
				<Select
					value={String(perPage)}
					onChange={(e, val) => onPerPageChange(Number(val))}
					sx={{
						padding: "5px",
						borderRadius: "5px",
						border: "1px solid #e0e0e0",
						background: "white",
					}}>
					<Option value="5">5</Option>
					<Option value="10">10</Option>
					<Option value="20">20</Option>
					<Option value="50">50</Option>
					<Option value="100">100</Option>
				</Select>
			</Box>
			<Box sx={{ mr: "10px" }}>
				{page} of {Math.ceil(total / perPage)}
			</Box>
			<IconButton variant="outlined" sx={{ mr: "10px" }} onClick={onPrevPress}>
				<West />
			</IconButton>
			<IconButton variant="outlined" onClick={onNextPress}>
				<East />
			</IconButton>
		</Box>
	);
}
