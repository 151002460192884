import { StateCreator, StoreApi } from "zustand";
import { IStore } from ".";

export interface User {
	_id: string;
	avatar: string;
	email: string;
	firstName: string;
	lastName: string;
	createdAt: string;
	updatedAt: string;
	clubId: string;
	myClubs: string[];
	lastLogin: number;
	uname: string;
	dateOfBirth: string;
	gender: string;
}

export interface IAuthSlice {
	authUser: User | null;
	login: (username: string, password: string) => Promise<void>;
	logout: () => Promise<void>;
	initAuth: () => Promise<void>;
	requestOtp: (email: string) => Promise<any>;
}

export const createAuthSlice: StateCreator<IStore, [], [], IAuthSlice> = (
	set: StoreApi<IStore>["setState"],
	get: StoreApi<IStore>["getState"]
) => ({
	authUser: null,

	login: async (username: string, password: string) => {
		const response = await get().app.authenticate({
			strategy: "venue-admin-local",
			username,
			password,
		});
		set({
			authUser: response.user,
		});
	},

	logout: async () => {
		localStorage.removeItem("feathers-jwt");
		set({
			authUser: null,
		});
	},

	initAuth: async () => {
		const response = await get().app.reAuthenticate();

		set({
			authUser: response.user,
		});
	},

	requestOtp: (email: string) => {
		return get()
			.app.service("signup-passwordless-user")
			.create({ email: email, clubId: "UNASSIGNED" });
	},
});
