import {
	Avatar,
	Box,
	Dropdown,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemContent,
	MenuButton,
	MenuItem,
	Typography,
} from "@mui/joy";
import logo from "../assets/logo.png";
import { useMemo } from "react";
import { DragHandle, Menu, Logout } from "@mui/icons-material";
import useStore from "../Stores";
import { Link, useLocation } from "react-router-dom";

interface Props {
	routes: any;
}

export default function HeaderComponent({ routes }: Props) {
	const Store = useStore();
	const location = useLocation();

	const activeRoute = useMemo(() => {
		return routes.find((route: any) => route.to === location.pathname);
	}, [location.pathname, routes]);

	return (
		<Box
			sx={{
				padding: {
					xs: "10px",
					md: "10px 140px",
				},
				borderBottom: "1px solid #e0e0e0",
				boxShadow: "0px 0px 10px 0px #0000000D",
				background: "white",
			}}>
			<Grid container>
				<Grid
					xs={6}
					md={4}
					sx={{
						height: "60px",
					}}>
					<img
						src={logo}
						alt="logo"
						style={{
							width: "50px",
							height: "50px",
							position: "relative",
							top: "50%",
							transform: "translateY(-50%)",
							borderRadius: "10px",
						}}
					/>
					{/* <span
						style={{ lineHeight: "50px", fontSize: 22, fontWeight: "bold" }}>
						ClubHub
					</span> */}
				</Grid>
				<Grid
					xs={12}
					md={4}
					sx={{
						height: "60px",
						display: {
							xs: "none",
							md: "block",
						},
					}}>
					<List
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							height: "100%",
						}}>
						{routes.map((route: any, index: any) => (
							<Link
								to={route.to}
								style={{
									textDecoration: "none",
								}}>
								<ListItemButton
									sx={{
										borderRadius: 10,
										margin: "0 10px",
										background:
											activeRoute?.to === route.to ? "#f5f5f5" : "transparent",
									}}>
									{route.label}
								</ListItemButton>
							</Link>
						))}
					</List>
				</Grid>
				<Grid
					xs={6}
					md={4}
					sx={{
						height: "50px",
						display: "flex",
						justifyContent: "flex-end",
					}}>
					{/* <IconButton
						variant="plain"
						sx={{
							display: "flex",
							alignItems: "center",
							mr: "15px",
						}}>
						<svg
							width="25px"
							height="25px"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
								stroke="#000000"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg> */}
					{/* </IconButton> */}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
						}}>
						{/* <Dropdown>
							<MenuButton
								slots={{ root: IconButton }}
								slotProps={{
									root: { variant: "outlined", color: "neutral" },
								}}
								sx={{ ml: "10px" }}>
								<Avatar
									sx={{
										display: {
											xs: "none",
											md: "flex",
										},
									}}>
									{nameInitials.toUpperCase()}
								</Avatar>
							</MenuButton>
							<Menu>
								<MenuItem>Remove</MenuItem>
							</Menu>
						</Dropdown> */}
						<IconButton
							onClick={Store.logout}
							sx={{
								display: {
									xs: "none",
									md: "flex",
								},
							}}>
							<Logout />
						</IconButton>

						<IconButton
							onClick={() => Store.toggleDrawer(true)}
							variant="outlined"
							sx={{
								display: {
									xs: "flex",
									md: "none",
								},
							}}>
							<Menu sx={{}} />
						</IconButton>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}
